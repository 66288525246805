import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Seo from "../components/Seo";

class RecipesPage extends React.Component {

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;



    return (
      <React.Fragment>


        <ThemeContext.Consumer>
          {theme => <Blog posts={posts} theme={theme} />}
        </ThemeContext.Consumer>

        <Seo facebook={facebook} />

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

RecipesPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default RecipesPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query RecipesQuery {
    posts: allMarkdownRemark(
    
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" },
      
        frontmatter: {
        category: {
          in: ["Recipe"]
        }
        }
        
      }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            ingredients
            author
            diet
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }

  }
`;
